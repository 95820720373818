<template>
  <div class="flex flex-col">
    <div class="flex justify-between bg-white p-6 header-vue">
      <h3 class="text-lg leading-6 font-medium text-gray-900 my-auto">
        <slot name="title"></slot>
      </h3>
      <div class="flex justify-between">
        <div class="flex justify-start items-center">
          <slot name="buttons"></slot>
        </div>
        <user-menu class="hidden md:flex"></user-menu>
      </div>
    </div>
  </div>
</template>
<script>
import UserMenu from "@/components/UserMenu";
import AlertNav from "@/components/AlertNav.vue";

export default {
  name: "Header",
  components: {
    AlertNav,
    UserMenu,
  },
  mounted() {},
};
</script>
