<template>
  <div class="rounded-md bg-yellow-50 sm:p-5 m-8">
    <div class="flex">
      <div class="flex-shrink-0"></div>
      <div class="ml-3">
        <div class="mt-2 text-sm leading-5 text-yellow-700">
          <p>
            {{
              $t(
                "your-application-is-pending-approval-please-upload-required-documents"
              )
            }}.
            <router-link to="/ib/upload-documents">
              <a class="text-indigo-600">{{ $t("click-here-to-upload") }}</a>
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CompleteIdIdentification",
};
</script>